import React from 'react';
import { Link } from 'react-router-dom';

export const Header = () => {
  return (
    <header className="bg-white shadow-md">
      <nav className="container mx-auto px-6 py-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              Attoe CTV Ads
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            <Link to="/solutions" className="text-gray-700 hover:text-blue-600">Solutions</Link>
            <Link to="/advertisers" className="text-gray-700 hover:text-blue-600">Advertisers</Link>
            <Link to="/publishers" className="text-gray-700 hover:text-blue-600">Publishers</Link>
            <Link to="/about" className="text-gray-700 hover:text-blue-600">About</Link>
            <Link to="/contact" className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700">
              Contact Us
            </Link>
          </div>
        </div>
      </nav>
    </header>
  );
}; 