import React from 'react';
import Layout from '../components/Layout/Layout';

const Publishers = () => {
  return (
    <Layout>
      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
          <div className="container mx-auto px-6">
            <div className="max-w-3xl">
              <h1 className="text-5xl font-bold mb-6">
                Maximize Your CTV Revenue
              </h1>
              <p className="text-xl mb-8">
                Join our premium publisher network and monetize your CTV inventory with top advertisers.
              </p>
              <button className="bg-white text-blue-600 px-8 py-3 rounded-md font-semibold hover:bg-gray-100">
                Become a Publisher
              </button>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Publisher Benefits</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-bold mb-4">Premium Demand</h3>
                <p className="text-gray-600">
                  Access to premium advertisers and brands looking to reach engaged audiences.
                </p>
              </div>
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-bold mb-4">Flexible Integration</h3>
                <p className="text-gray-600">
                  Easy integration with your existing ad tech stack and multiple monetization options.
                </p>
              </div>
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-xl font-bold mb-4">Real-Time Analytics</h3>
                <p className="text-gray-600">
                  Comprehensive reporting and insights to optimize your inventory performance.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="bg-gray-50 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Publisher Features</h2>
            <div className="grid md:grid-cols-2 gap-8">
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="w-6 h-6 mt-1 text-blue-600">✓</div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Advanced Ad Serving</h3>
                    <p className="text-gray-600">
                      Sophisticated ad serving capabilities with support for multiple ad formats.
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="w-6 h-6 mt-1 text-blue-600">✓</div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Yield Optimization</h3>
                    <p className="text-gray-600">
                      Automated tools to maximize your inventory value and revenue.
                    </p>
                  </div>
                </div>
              </div>
              <div className="space-y-6">
                <div className="flex items-start space-x-4">
                  <div className="w-6 h-6 mt-1 text-blue-600">✓</div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Brand Safety</h3>
                    <p className="text-gray-600">
                      Advanced controls and filters to ensure brand-safe advertising.
                    </p>
                  </div>
                </div>
                <div className="flex items-start space-x-4">
                  <div className="w-6 h-6 mt-1 text-blue-600">✓</div>
                  <div>
                    <h3 className="text-xl font-bold mb-2">Support & Services</h3>
                    <p className="text-gray-600">
                      Dedicated support team and technical assistance for publishers.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Publishers; 