import React from 'react';
import Layout from '../components/Layout/Layout';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <Layout>
      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-32">
          <div className="container mx-auto px-6">
            <div className="max-w-4xl">
              <h1 className="text-6xl font-bold mb-8 leading-tight">
                Attoe CTV Ads - The Future of Connected TV Advertising
              </h1>
              <p className="text-xl mb-10 text-blue-100">
                Connect with millions of engaged viewers through Attoe CTV Ads premium inventory. 
                Advanced targeting, real-time analytics, and seamless campaign management.
              </p>
              <div className="flex flex-wrap gap-4">
                <Link to="/advertisers" 
                  className="bg-white text-blue-600 px-8 py-4 rounded-md font-semibold hover:bg-gray-100 transition-colors">
                  Start Advertising
                </Link>
                <Link to="/publishers" 
                  className="border-2 border-white text-white px-8 py-4 rounded-md font-semibold hover:bg-white/10 transition-colors">
                  Become a Publisher
                </Link>
              </div>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-6">
            <div className="grid md:grid-cols-4 gap-8 text-center">
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">120M+</div>
                <div className="text-gray-600">Households Reached</div>
              </div>
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">500+</div>
                <div className="text-gray-600">Premium Channels</div>
              </div>
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">98%</div>
                <div className="text-gray-600">Completion Rate</div>
              </div>
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">2B+</div>
                <div className="text-gray-600">Monthly Impressions</div>
              </div>
            </div>
          </div>
        </section>

        {/* Features Section */}
        <section className="py-20 bg-gray-50">
          <div className="container mx-auto px-6">
            <h2 className="text-4xl font-bold text-center mb-16">Why Choose Our Platform</h2>
            <div className="grid md:grid-cols-3 gap-12">
              <div className="text-center">
                <div className="bg-blue-600 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
                  <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-4">Lightning Fast Setup</h3>
                <p className="text-gray-600">
                  Launch your first campaign in minutes with our intuitive platform.
                </p>
              </div>
              <div className="text-center">
                <div className="bg-blue-600 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
                  <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-4">Real-Time Analytics</h3>
                <p className="text-gray-600">
                  Track performance and optimize campaigns with detailed insights.
                </p>
              </div>
              <div className="text-center">
                <div className="bg-blue-600 w-16 h-16 rounded-full flex items-center justify-center mx-auto mb-6">
                  <svg className="w-8 h-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                  </svg>
                </div>
                <h3 className="text-xl font-bold mb-4">Advanced Targeting</h3>
                <p className="text-gray-600">
                  Reach your ideal audience with precise targeting options.
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Integration Platforms Section */}
        <section className="py-20 bg-white">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-4">Integrated With Leading Platforms</h2>
            <p className="text-gray-600 text-center mb-12 max-w-2xl mx-auto">
              Seamlessly connect with your favorite measurement and attribution platforms
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-12 items-center max-w-4xl mx-auto">
              <div className="flex flex-col items-center">
                <div className="w-48 h-24 flex items-center justify-center mb-4 px-4">
                  <img 
                    src="/images/platforms/appsflyer-logo.svg" 
                    alt="Appsflyer" 
                    className="h-8 object-contain"
                  />
                </div>
                <span className="text-sm text-gray-500">Attribution & Analytics</span>
              </div>
              
              <div className="flex flex-col items-center">
                <div className="w-48 h-24 flex items-center justify-center mb-4 px-4">
                  <img 
                    src="/images/platforms/adjust-logo.svg" 
                    alt="Adjust" 
                    className="h-8 object-contain"
                  />
                </div>
                <span className="text-sm text-gray-500">Mobile Measurement</span>
              </div>
              
              <div className="flex flex-col items-center">
                <div className="w-48 h-24 flex items-center justify-center mb-4 px-4">
                  <img 
                    src="/images/platforms/branch-logo.svg" 
                    alt="Branch" 
                    className="h-8 object-contain"
                  />
                </div>
                <span className="text-sm text-gray-500">Deep Linking & Attribution</span>
              </div>
            </div>

            {/* Integration Benefits */}
            <div className="mt-16 grid md:grid-cols-3 gap-8">
              <div className="text-center">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <h3 className="font-bold mb-2">Quick Integration</h3>
                <p className="text-gray-600">Connect your preferred platform in minutes</p>
              </div>
              
              <div className="text-center">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                  </svg>
                </div>
                <h3 className="font-bold mb-2">Data Synchronization</h3>
                <p className="text-gray-600">Real-time data flow between platforms</p>
              </div>
              
              <div className="text-center">
                <div className="w-12 h-12 bg-blue-100 rounded-full flex items-center justify-center mx-auto mb-4">
                  <svg className="w-6 h-6 text-blue-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 12l2 2 4-4m5.618-4.016A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016z" />
                  </svg>
                </div>
                <h3 className="font-bold mb-2">Secure & Reliable</h3>
                <p className="text-gray-600">Enterprise-grade security standards</p>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section className="py-20 bg-blue-600">
          <div className="container mx-auto px-6 text-center">
            <h2 className="text-4xl font-bold text-white mb-8">Ready to Transform Your TV Advertising with Attoe CTV Ads?</h2>
            <p className="text-xl text-blue-100 mb-10 max-w-2xl mx-auto">
              Join thousands of advertisers and publishers who are already succeeding with Attoe CTV Ads.
            </p>
            <Link to="/contact" 
              className="bg-white text-blue-600 px-8 py-4 rounded-md font-semibold hover:bg-gray-100 transition-colors inline-block">
              Get Started Today
            </Link>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Home; 