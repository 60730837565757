import React from 'react';
import Layout from '../components/Layout/Layout';

const Advertisers = () => {
  return (
    <Layout>
      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
          <div className="container mx-auto px-6">
            <div className="max-w-3xl">
              <h1 className="text-5xl font-bold mb-6">
                Transform Your TV Advertising Strategy
              </h1>
              <p className="text-xl mb-8">
                Access premium CTV inventory and reach millions of engaged viewers in minutes.
              </p>
              <button className="bg-white text-blue-600 px-8 py-3 rounded-md font-semibold hover:bg-gray-100">
                Start Advertising
              </button>
            </div>
          </div>
        </section>

        {/* Benefits Section */}
        <section className="py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Why Advertise With Us</h2>
            <div className="grid md:grid-cols-3 gap-8">
              <div className="text-center">
                <div className="text-4xl font-bold text-blue-600 mb-4">500+</div>
                <h3 className="text-xl font-bold mb-2">Premium Channels</h3>
                <p className="text-gray-600">
                  Access top-tier streaming platforms and TV channels
                </p>
              </div>
              <div className="text-center">
                <div className="text-4xl font-bold text-blue-600 mb-4">5min</div>
                <h3 className="text-xl font-bold mb-2">Quick Setup</h3>
                <p className="text-gray-600">
                  Launch your first campaign in minutes
                </p>
              </div>
              <div className="text-center">
                <div className="text-4xl font-bold text-blue-600 mb-4">$50</div>
                <h3 className="text-xl font-bold mb-2">Minimum Start</h3>
                <p className="text-gray-600">
                  Begin with a budget that works for you
                </p>
              </div>
            </div>
          </div>
        </section>

        {/* Targeting Section */}
        <section className="bg-gray-50 py-20">
          <div className="container mx-auto px-6">
            <h2 className="text-3xl font-bold text-center mb-12">Advanced Targeting Options</h2>
            <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-6">
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="font-bold mb-3">Demographics</h3>
                <ul className="text-gray-600 space-y-2">
                  <li>• Age groups</li>
                  <li>• Income levels</li>
                  <li>• Education</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="font-bold mb-3">Location</h3>
                <ul className="text-gray-600 space-y-2">
                  <li>• National</li>
                  <li>• Regional</li>
                  <li>• Local targeting</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="font-bold mb-3">Interests</h3>
                <ul className="text-gray-600 space-y-2">
                  <li>• Shopping habits</li>
                  <li>• Entertainment</li>
                  <li>• Lifestyle</li>
                </ul>
              </div>
              <div className="bg-white p-6 rounded-lg shadow">
                <h3 className="font-bold mb-3">Behavior</h3>
                <ul className="text-gray-600 space-y-2">
                  <li>• Viewing patterns</li>
                  <li>• Device usage</li>
                  <li>• Purchase intent</li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Advertisers; 