import React from 'react';
import Layout from '../components/Layout/Layout';

const Solutions = () => {
  return (
    <Layout>
      <div className="min-h-screen">
        {/* Hero Section */}
        <section className="bg-gradient-to-r from-blue-600 to-blue-800 text-white py-20">
          <div className="container mx-auto px-6">
            <div className="max-w-3xl">
              <h1 className="text-5xl font-bold mb-6">
                Complete CTV Advertising Solutions
              </h1>
              <p className="text-xl mb-8">
                Everything you need to succeed in Connected TV advertising - from targeting to measurement.
              </p>
            </div>
          </div>
        </section>

        {/* Features Grid */}
        <section className="py-20">
          <div className="container mx-auto px-6">
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold mb-4">Targeting</h3>
                <p className="text-gray-600 mb-4">
                  Reach specific audiences based on demographics, interests, and viewing behavior.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li>• Demographic targeting</li>
                  <li>• Interest-based segments</li>
                  <li>• Geographic targeting</li>
                </ul>
              </div>

              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold mb-4">Performance</h3>
                <p className="text-gray-600 mb-4">
                  Track and optimize your campaigns in real-time with advanced analytics.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li>• Real-time reporting</li>
                  <li>• Conversion tracking</li>
                  <li>• ROI measurement</li>
                </ul>
              </div>

              <div className="p-6 bg-white rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold mb-4">Campaign Management</h3>
                <p className="text-gray-600 mb-4">
                  Easy-to-use tools for managing your CTV advertising campaigns.
                </p>
                <ul className="space-y-2 text-gray-600">
                  <li>• Automated optimization</li>
                  <li>• Budget management</li>
                  <li>• Creative A/B testing</li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* Stats Section */}
        <section className="bg-gray-50 py-20">
          <div className="container mx-auto px-6">
            <div className="grid md:grid-cols-3 gap-8 text-center">
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">$0.02</div>
                <div className="text-gray-600">Average Cost Per View</div>
              </div>
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">120M+</div>
                <div className="text-gray-600">Households Reached</div>
              </div>
              <div>
                <div className="text-4xl font-bold text-blue-600 mb-2">500+</div>
                <div className="text-gray-600">Premium Channels</div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Solutions; 